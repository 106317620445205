import React from "react";
import OurStory from "../components/OurStory";

const OurStoryPage = () => {
  return (
    <div>
      <OurStory />
    </div>
  );
};

export default OurStoryPage;
