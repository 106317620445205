import React from "react";
import WeddingParty from "../components/WeddingParty";

const WeddingPartyPage = () => {
  return (
    <div>
      <WeddingParty />
    </div>
  );
};

export default WeddingPartyPage;
