import React from "react";
import FAQ from "../components/FAQ";

const FAQPage = () => {
  return (
    <div>
      <FAQ />
    </div>
  );
};

export default FAQPage;
