import React from "react";
import Travel from "../components/Travel";

const TravelPage = () => {
  return (
    <div>
      <Travel />
    </div>
  );
};

export default TravelPage;
