import React from "react";
import Registry from "../components/Registry";

const RegistryPage = () => {
  return (
    <div>
      <Registry />
    </div>
  );
};

export default RegistryPage;
