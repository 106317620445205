import React from "react";
import RSVPForm from "../components/RSVP";

const RSVPPage = () => {
  return (
    <div>
      <RSVPForm />
    </div>
  );
};

export default RSVPPage;
